import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Modal, Button, Form, Input, message  } from "antd";
import {redirectToLogout} from "../utils";


import { sm2Encrypt } from "../sm";

import ajax from "../api";

const PasswordPage = (props) => {
  const { visible } = props;
  const [form] = Form.useForm();

  const handleOk = () => {
    form.validateFields().then(async (values) => {
      const res = await ajax.ChangePwd({
        originalPassword: values.originalPassword,
        password: values.password,
        confirmPassword: values.confirmPassword,
        // originalPassword: sm2Encrypt(values.originalPassword),
        // password: sm2Encrypt(values.password),
        // confirmPassword: sm2Encrypt(values.confirmPassword),
      });
      if (res.data.successful) {
        message.success('修改成功，请重新登录');
        props.onClose();
        form.resetFields();
        redirectToLogout();
        // window.location.href = 'http://microvpp.swdnkj.com/sso/logout?redirect=http://microvpp.swdnkj.com/';
      } else {
        message.error(res.data.message);
        form.resetFields();
      }
    }).catch((err) => {});
  };

  const handleCancel = () => {
    form.resetFields();
    props.onClose();
  };

  return (
    <Modal title="修改密码" open={visible} onOk={handleOk} onCancel={handleCancel} cancelText="取消" okText="提交">
      <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
        <Form.Item label="旧密码" name= "originalPassword" rules={[{ required: true, message: '请输入旧密码' }]}>
          <Input.Password placeholder="请输入旧密码" />
        </Form.Item>
        <Form.Item label="新密码" name="password" rules={[{ required: true, message: '请输入新密码' }]}>
          <Input.Password placeholder="请输入新密码" />
        </Form.Item>
        <Form.Item
          label="确认新密码"
          name="confirmPassword"
          rules={[
            { required: true, message: '请再次输入新密码' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('两次密码输入不一致!'));
              },
            }),
          ]}
        >
          <Input.Password placeholder="请再次输入新密码" />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default PasswordPage;